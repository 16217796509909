import { useContext } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "./grid";
import { Library } from "@emotion-icons/heroicons-outline/Library";
import { Briefcase } from "@emotion-icons/heroicons-outline/Briefcase";
import { PeopleOutline } from "@emotion-icons/material";
import { LanguageContext } from "../language";

const Container = styled.div`
  fill: #2b2f40;
  color: #666c84;
  background-color: #2b2f40;
  padding: 40px 0 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: calc(100vh - 780px);
`;

const Divider = styled.div`
  max-width: 100%;
  margin: 13px auto;
  transition: 0.33s all ease;
  width: 49px;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  background: #cca876;
  fill: #cca876;
  color: #fff;
  box-sizing: border-box;
`;

const CardTop = styled.div`
  position: relative;
  will-change: transform;
  transition: 0.33s all ease;
  top: 0;
  margin-top: 35px;
`;

const Card = styled.div`
  //max-width: 308px;
  padding: 20px 40px 20px 40px;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 400px;
  margin: 0 31px 0 31px;
  border: 1px solid #e5e7e9;
  :hover::before, :hover::after {
        width: 100%;
        height: 100%;
        border-color: #cca876;
  }
  :hover ${Divider} {
    width: 168px;
  }
  :hover ${CardTop} {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
  }
`;

const CardHeader = styled.div`
  margin-top: 5px;
  font-size: 30px;
  font-family: "Times New Roman", Times, serif;
  color: #fff;
`;

const CardBody = styled.div`
  width: 100%;
  position: relative;
  will-change: transform;
  transition: 0.33s all ease;
  -webkit-filter: blur(0);
  box-sizing: border-box;
  display: block;
  margin: 10px 0;
  padding: 10px 20px 10px 20px;
    ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 35px);
    height: calc(100% - 35px);
    border: 1px solid #e5e7e9;
    border-width: 1px 0 0 1px;
    pointer-events: none;
    transition: 0.33s all ease;
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% - 35px);
    height: calc(100% - 35px);
    border: 1px solid #e5e7e9;
    border-width: 0 1px 1px 0;
    pointer-events: none;
    transition: 0.33s all ease;
  }

`;

const CardText = styled.p`
  color: #9094a6;
`;

const CardLibrary = styled(Library)`
  color: #cca876;
  height: 50px;
  width: 50px;
`;

const CardBriefcase = styled(Briefcase)`
  color: #cca876;
  height: 50px;
  width: 50px;
`;

const CardUsers = styled(PeopleOutline)`
  color: #cca876;
  height: 50px;
  width: 50px;
`;

const H5 = styled.h5`
    margin: 5px 0 5px;
`

export default function IvpSection() {

    const { strings } = useContext(LanguageContext);

    return (
    <Container>
      <Grid>
        <Row>
          <Col $size={1} $collapse="lg"></Col>
          <Col $size={2}>
            <Card>
              <CardTop>
                <CardLibrary />
                <CardHeader>
                  <H5>{strings["Inovacijos"]}</H5>
                </CardHeader>
              </CardTop>
              <Divider />
              <CardBody>
                <CardText>{strings["VersloAplinka"]}</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col $size={2}>
            <Card>
              <CardTop>
                <CardBriefcase />
                <CardHeader>
                  <H5>{strings["Vertė"]}</H5>
                </CardHeader>
              </CardTop>
              <Divider />
              <CardBody>
                <CardText>{strings["MūsųPaslaugosEsmė"]}</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col $size={2}>
            <Card>
              <CardTop>
                <CardUsers />
                <CardHeader>
                  <H5>{strings["Profesionalai"]}</H5>
                </CardHeader>
              </CardTop>
              <Divider />
              <CardBody>
                <CardText>{strings["MesSpecializuojamės"]}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col $size={1} $collapse="lg"></Col>
        </Row>
      </Grid>
    </Container>
  );
}
