const media = {
    xs: (styles) => `
      @media only screen and (max-width: 576px) {
          ${styles}
      }`,
    sm: (styles) => `
      @media only screen and (max-width: 768px) {
          ${styles}
      }`,
    md: (styles) => `
      @media only screen and (max-width: 992px) {
          ${styles}
      }`,
    lg: (styles) => `
      @media only screen and (max-width: 1200px) {
          ${styles}
      }`,
    xl: (styles) => `
      @media only screen and (max-width: 1399px) {
          ${styles}
      }`,
    xxl: (styles) => `
      @media only screen and (min-width: 1400px) {
          ${styles}
      }`,
  };

  export default media;