import styled from "styled-components";

const StyledButton = styled.button`
  background-color: ${({ other }) =>
    other ? "hsla(40, 72%, 50%, 1)" : "#cca876"};
  border: 1px solid
    ${({ other }) =>
      other ? "hsla(40, 72%, 60%, 1)" : "#bb8c49"};
  white-space: nowrap;
  color: #fff;
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  text-shadow: 0.1rem 0.1rem 0.5rem hsla(0, 0%, 0%, 0.5);
  letter-spacing: 0.1rem;
  border-radius: 0.2rem;
  user-select: none;
  padding: 0.7rem 1.7rem;
  margin: 0rem;
  transition: all 0.1s ease-in;

  ::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    background-color: ${({ other }) =>
      other ? "hsla(40, 72%, 60%, 1)" : "#be9051"};
    ${({ other }) => other && `transform: translateY(-3px)`}
  }

  &:active {
    background-color: ${({ other }) =>
      other ? "hsla(40, 72%, 35%, 1)" : "#baa200"};
  }

  &:disabled, [disabled] {
    background-color: #fff;
    color: #212529;
  }

  @media screen and (max-width: 45em) {
    font-size: 1rem;
  }
`;

export default StyledButton;
