import styled from "styled-components";
import media from "./media";

const Grid = styled.div`
  width: 100%;
  ${(props) => props.$marginTop ? `margin-top: ${props.$marginTop};` : null};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
  margin-bottom: ${(props) => props.$marginBottom}rem;
`;

const Col = styled.div`
  flex: ${(props) => props.$size};
  ${(props) => props.$collapse && media[props.$collapse](`display: none;`)};
  border-bottom: ${(props) => (props.$border ? "solid 1px #d6d6d6" : "none")};
  padding-bottom: 0.5rem;
  padding-left: ${(props) => props.$paddingLeft}rem;
`;

export { Grid, Row, Col };
