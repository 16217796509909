import React, { useContext } from "react";
import styled from "styled-components";
import StyledButton from "./button";
import { LanguageContext } from "../language";
import { Grid, Row, Col } from "./grid";
import DropzoneStyled from "./dropzone";
import { StyledInput } from "./styledInput";
import { toast } from "react-toastify";

const Tekstas = styled.p``;

const RemoveButton = styled(StyledButton)`
  padding: 1px 2px 1px 2px;
  letter-spacing: 0;
  font-size: small;
`;

const A = styled.a`
  color: #be9051;
  text-decoration: none;
  &:hover {
    color: #666;
  }
`;

export default function Upload({ galiojaIki, kodas, postFailai }) {
  const { strings } = useContext(LanguageContext);
  const [failai, setFailai] = React.useState([]);
  const [tekstasIsorinis, setTekstasIsorinis] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);

  const handleDrop = () => {
    setIsSaving(true);
  };

  const handleRemoveClick = (e) => {
    var f = failai.filter((i) => i.kelias !== e.target.id);
    setFailai(f);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!failai.length) {
      toast.warning(strings["NeraKoSaugoti"]);
      return;
    }

    postFailai(tekstasIsorinis, failai, setIsSaving);
  };

  const handleUpload = (data) => {
    setFailai(data.concat(failai));
    if (data.length === 0) toast.warning(strings["NeiVienasFailasNeįkeltas"]);
    else if (data.length === 1)
      toast.success(strings["FailasĮkeltasSėkmingai"]);
    else toast.success(strings["SėkmingaiĮkeltiFailai"] + data.length);
  };

  const renderUploaded = (f) => (
    <div key={f.kelias}>
      <A href={f.preview} target="_blank" rel="noopener">
        {f.originalusPavadinimas}
      </A>{" "}
      <RemoveButton type="button" id={f.kelias} onClick={handleRemoveClick}>
        {strings["Pašalinti"]}
      </RemoveButton>
    </div>
  );

  return (
    <Grid $marginTop="5rem">
      <form onSubmit={handleSubmit}>
        <Row $marginBottom={3}>
          <Col $size={1}>
            <Tekstas>{strings["GaliteSaugiaiIkelti"]}</Tekstas>
            <Tekstas>
              {strings["NuorodaGaliojaIki"]} <strong>{galiojaIki}</strong>
              {strings["JeiNorėsite"]}
            </Tekstas>
          </Col>
        </Row>
        <Row $marginBottom={2}>
          <Col $size={1}>
            <DropzoneStyled
              disabled={isSaving}
              handleDrop={handleDrop}
              handleUpload={handleUpload}
              kodas={kodas}
              multiple={true}
              setIsSaving={setIsSaving}
              title="Įkelkite failus"
            />
          </Col>
          {failai && failai.length ? (
            <Col $size={1} $paddingLeft={1}>
              {failai.map(renderUploaded)}
            </Col>
          ) : null}
        </Row>
        <Row $marginBottom={1}>
          <Col $size={1}>
            <StyledInput
              type="text"
              value={tekstasIsorinis}
              onChange={(e) => setTekstasIsorinis(e.target.value)}
              placeholder="Informacija brokeriui."
              title="Įrašykite čia brokeriui aktualią informaciją apie pateikiamus failus. Tai gali būti failų aprašymas, ar kitokia pastaba."
            />
          </Col>
        </Row>
        <Row>
          <Col $size={1}>
            <StyledButton type="submit" disabled={isSaving}>
              {strings["Saugoti"]}
            </StyledButton>
          </Col>
        </Row>
      </form>
    </Grid>
  );
}
