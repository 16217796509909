import React, { useContext } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "./grid";
import StyledButton from "./button";
import { StyledPassword } from "./styledInput";
import { LanguageContext } from "../language";

const Message = styled.span`
  color: red;
`;

export default function Login({ kodas, setError, setFailai, setLogin }) {
  const { strings } = useContext(LanguageContext);
  const [password, setPassword] = React.useState();
  const [message, setMessage] = React.useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password) {
      setMessage("SlaptazodisReikalingas");
      return;
    }
    setMessage();
    postLogin();
  };

  const postLogin = async () => {
    try {
      var url = process.env.REACT_APP_API + `failai/postLogin/${kodas}`;
      var response = await fetch(url, {
        body: JSON.stringify(password),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      var data = await response.json();
      if (response.status === 400) {
        throw data.message;
      }
      if (response.status === 200) {
        setFailai(data.failai);
        setError();
        setLogin();
        setMessage();
      }
    } catch (error) {
      setMessage(error);
    }
  };

  return (
    <Grid $marginTop="3rem">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col $size={1}>
            <StyledPassword
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={strings["Slaptažodis"]}
            />
          </Col>
        </Row>
        <Row>
          <Col $size={1}>{message && <Message>{strings[message]}</Message>}</Col>
        </Row>
        <Row>
          <Col>
            <StyledButton type="submit">{strings["Prisijungti"]}</StyledButton>
          </Col>
        </Row>
      </form>
    </Grid>
  );
}
