import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageContext } from "../language";
import background from "../home-2.jpg";
import StyledButton from "./button";

const Pranesimas = styled.div`
  background-image: url(${background});
  background-color: #2b2f40;
  padding: 120px 0 120px;
  text-align: center;
  @media only screen and (max-width: 575px) {
    background-image: none;
    padding: 40px 0 10px;
  }
`;

const Tekstas = styled.p``;

const H3 = styled.h3`
  color: #7c0000;
  font-family: "Times New Roman", Times, serif;
  margin: 0 0 20px;
  @media only screen and (max-width: 575px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 22px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 26px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 30px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 32px;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 46px;
  } ;
`;

const H4 = styled.h4`
  color: #901111;
  margin: 0;
  font-family: "Times New Roman", Times, serif;
  @media only screen and (max-width: 575px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 20px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 22px;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 28px;
  } ;
`;

const SectionWhite = styled.div`
  background-color: #fff;
  text-align: center;
  padding: 0 0 20px;
`

export default function Error({ error, tipas, setError }) {
  const { strings } = useContext(LanguageContext);

  const handleClick = (e) => {
    setError();
  };

  return (
    <>
      <Pranesimas>
        <H3>{strings["AciuKadApsilankete"]}</H3>
        <H4>{strings[error] || error}</H4>
      </Pranesimas>
      {tipas && tipas === 2 ? (
        <SectionWhite>
          <Tekstas>{strings["AtgalTekstas"]}</Tekstas>
          <StyledButton onClick={handleClick}>{strings["Atgal"]}</StyledButton>
        </SectionWhite>
      ) : null}
    </>
  );
}
