import React, {
	useState,
	createContext,
	useCallback,
	useEffect,
	useRef
} from "react"

export const LanguageContext = createContext({
	language: "lt",
	strings: {},
	updateLanguage: () => {}
})

export const LanguageProvider = ({ children, fetchTranslations }) => {
	try {
		var lang = localStorage.getItem("failai_language") || "lt";
	} catch(error) { 
		lang = "lt";
		console.log("Local storage is not available. Cookies are blocked in browser.");
	 }
	const [{ language, strings }, setLanguage] = useState({
	  language: lang,
	  strings: {},
	});
	const initialStringsLoaded = useRef(false);
  
	const updateLanguage = useCallback(
	  async (newLang) => {
		if (initialStringsLoaded.current && newLang === language) return;
		try {
			localStorage.setItem("failai_language", newLang);
		} catch(error) {
			console.log("Local storage is not available. Cookies are blocked in browser.");
		}
		const newStrings = await fetchTranslations({ language: newLang });
		initialStringsLoaded.current = true;
		setLanguage({
		  language: newLang,
		  strings: newStrings,
		});
	  },
	  [language, fetchTranslations]
	);
  
	useEffect(() => {
	  updateLanguage(language);
	}, [language, updateLanguage]);
  
	const context = {
	  language,
	  strings,
	  updateLanguage: updateLanguage,
	};
  
	return <LanguageContext.Provider value={context}>{children}</LanguageContext.Provider>;
  };