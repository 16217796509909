import styled from "styled-components";

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${(props) => (props.$error ? `border-color: red` : null)};
  box-sizing: border-box;
`;

const StyledPassword = styled(StyledInput)`
  @media only screen and (max-width: 575px) {
    width: 100%;
    padding: 0.375rem 0 0.375rem 6px;
  }
  @media only screen and (min-width: 576px) {
    width: 60%;
  }
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
  @media only screen and (min-width: 992px) {
    width: 30%;
  }
  @media only screen and (min-width: 1200px) {
    width: 30%;
  }
  @media only screen and (min-width: 1400px) {
    width: 20%;
  } ;
`;

export { StyledInput, StyledPassword };
