import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
//import {SAFE_FILE_TYPES} from "../constants";

const baseStyle = {
  width: "auto",
  minHeight: 100,
  padding: "5px 5px 5px 20px",
  borderWidth: 2,
  borderColor: "#666",
  borderStyle: "dashed",
  borderRadius: 5,
};
const activeStyle = {
  borderWidth: 3,
  borderStyle: "solid",
  borderColor: "#6c6",
  backgroundColor: "#eee",
};
const rejectStyle = {
  borderWidth: 3,
  borderStyle: "solid",
  borderColor: "#c66",
  backgroundColor: "#eee",
};

export const SAFE_FILE_TYPES = {
    'application/msword': [],
    'application/pdf': [],
    'application/rtf': [],
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    'application/vnd.ms-outlook': ['.msg'],
    'application/vnd.oasis.opendocument.text': [],
    'application/vnd.oasis.opendocument.presentation': [],
    'application/vnd.ms-powerpoint': [],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'application/x-vnd.oasis.opendocument.spreadsheet': [],
    'application/zip': [],
    'audio/m4a': [],
    'audio/mpeg': [],
    'image/gif': [],
    'image/jpeg': [],
    'image/png': [],
    'image/tiff': [],
    'image/wmf': [],
    'text/csv': [],
    'text/html': [],
    'text/plain': [],
    'text/x-csv': [],
    'video/mp4': [],
    'video/quicktime': [],
    'video/x-msvideo': [],
}

//export const SAFE_FILE_TYPES =
//    "text/csv, text/x-csv, text/html, text/plain, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .msg, application/vnd.ms-outlook, application/vnd.oasis.opendocument.text, application/x-vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.presentation, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rtf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, audio/*, 	audio/m4a, audio/mpeg, image/*, image/gif, image/png, image/jpeg, image/tiff, image/wmf, video/*, video/mp4, video/x-msvideo, video/quicktime";


export default function DropzoneStyled({
  disabled,
  handleDrop,
  handleUpload,
  kodas,
  multiple,
  setIsSaving,
  title,
}) {

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (handleDrop) handleDrop();
      var body = new FormData();
      acceptedFiles.forEach((f) => {
        body.append("files", f);
      });

      try {
        const headers = new Headers();
        headers.append("Accept", "application/json");
        //headers.append("Content-Type", "multipart/form-data");

        const options = {
          method: "POST",
          credentials: "include",
          headers,
          body,
        };

        var urlFull = new URL(process.env.REACT_APP_API + "failai/upload/"+kodas);
        var res = await fetch(urlFull, options);
        if (res.ok) {
          var failai = await res.json();
          failai.forEach(f => {
            var file = acceptedFiles.find(i => i.name === f.originalusPavadinimas);
            if(file) f.preview = URL.createObjectURL(file);
          });
          if (handleUpload) handleUpload(failai);
        }
        else {
            var error = await res.json();
            throw error;
        }
      } catch (error) {
        toast.error(error.message ? error.message : error);
      } finally {
        setIsSaving(false);
      }
      
    },
    [handleDrop, handleUpload, kodas, setIsSaving]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: SAFE_FILE_TYPES,
      multiple: multiple,
      disabled: disabled,
    });

  let styles = { ...baseStyle };
  styles = isDragActive ? { ...styles, ...activeStyle } : styles;
  styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

  return (
    <div {...getRootProps()} style={styles}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Nutempkite failus čia...</p>
      ) : (
        <p>
          {title ||
            "Nutempkite failus čia arba paspauskite norėdami pasirinkti failus..."}
        </p>
      )}
    </div>
  );
}
