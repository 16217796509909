import React, { useContext } from "react";
import styled from "styled-components";
import logo from "../ivp_firminis_spalvotas.png";
import { LocalPhone, Email, Language } from "@emotion-icons/material";
import { LanguageContext } from "../language";
import Logo from "./logo";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0 30px 0 80px;
  min-height: 80px;
  background-color: #ffffff;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin: 10px 0 0 0;
    padding: 0 10px 10px 10px;
  }
`;

const HeaderText = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 576px) {
    margin: 10px 200px 0px 0px;
  }
  @media only screen and (min-width: 768px) {
    margin: 10px 30px 0px 0px;
  }
  @media only screen and (min-width: 992px) {
    margin: 10px 80px 0px 0px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 10px 150px 0px 0px;
  }
  @media only screen and (min-width: 1400px) {
    margin: 10px 200px 0px 0px;
  } ;
`;

const LanguageBtn = styled.button`
  border: none;
  color: ${(props) => (props.active ? `#cca876;` : "#9b9b9b;")};
  //color: #9b9b9b;
  background-color: #fff;
  &:hover {
    color: #cca876;
  }
`;

export default function Header() {
  const { language, updateLanguage } = useContext(LanguageContext);

  return (
    <Container $query="sm">
      <Logo src={logo}></Logo>
      <HeaderText>
        <LocalPhone size={20} color="#cca876" /> (+370 5) 2197601
      </HeaderText>
      <HeaderText>
        <Email size={20} color="#cca876" /> info@ivp.lt
      </HeaderText>
      <HeaderText>
        <Language size={20} color="#cca876" />
        <LanguageBtn
          $active={language === "lt"}
          onClick={() => updateLanguage("lt")}
        >
          LT
        </LanguageBtn>
        <LanguageBtn
          $active={language === "en"}
          onClick={() => updateLanguage("en")}
        >
          EN
        </LanguageBtn>
      </HeaderText>
    </Container>
  );
}
