import React, { useContext } from "react";
import styled from "styled-components";
import StyledButton from "./button";
import { LanguageContext } from "../language";
import { Grid, Row, Col } from "./grid";
import { saveAs } from "file-saver";

const Tekstas = styled.p``;

export default function Failai({ failai, galiojaIki, kodas, setError }) {
  const { strings } = useContext(LanguageContext);

  const getFailas = async (id, failoPavadinimas) => {
    try {
      var url = process.env.REACT_APP_API + `failai/getFailas/${id}/${kodas}`;
      var response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      if (response.status === 400) {
        var data = await response.json();
        throw data.message;
      }
      if (response.status === 200) {
        var blob = await response.blob();
        saveAs(blob, failoPavadinimas);
        setError();
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleClickZip = async (e) => {
    e.preventDefault();
    try {
      var url = process.env.REACT_APP_API + `failai/getZip/${kodas}`;
      var response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });
      if (response.status === 400) {
        var data = await response.json();
        throw data.message;
      }
      if (response.status === 200) {
        var blob = await response.blob();
        saveAs(blob, "archyvas.zip");
        setError();
      }
    } catch (error) {
      setError(error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    getFailas(e.target.name, e.target.value);
  };

  return (
    <Grid $marginTop="5rem">
      <Row $marginBottom={7}>
        <Col $size={1}>
          <Tekstas>{strings["JumsBuvoSaugiaiPersiusti"]}</Tekstas>
          <Tekstas>
            {strings["NuorodaGaliojaIki"]} <strong>{galiojaIki}</strong>
            {strings["JeiNorėsite"]}
          </Tekstas>
        </Col>
      </Row>
      {failai.map((f) => (
        <Row key={f.failasId} $marginBottom={0.5}>
          <Col $size={1} $border>
            {f.originalusPavadinimas}
          </Col>
          <Col $size={1} $collapse="sm" $border>
            {f.dydisString}
          </Col>
          <Col $size={1} $border>
            <StyledButton
              other
              value={f.originalusPavadinimas}
              name={f.failasId}
              onClick={handleClick}
            >
              {strings["Atsisiųsti"]}
            </StyledButton>
          </Col>
          <Col $size={2} $collapse="sm"></Col>
        </Row>
      ))}
      <Row $marginBottom={4}>
        <Col $size={1}>
          <StyledButton other onClick={handleClickZip}>
            {strings["AtsisiųstiViską"]}
          </StyledButton>
        </Col>
        <Col $size={3} $collapse="sm"></Col>
      </Row>
    </Grid>
  );
}
