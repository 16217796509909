import React, { useContext } from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "./grid";
//import { ArrowRight } from "@emotion-icons/material";
import { Email, Call } from "@emotion-icons/material";
import logo from "../IVP_logo_white.png";
import { LanguageContext } from "../language";

const FooterTop = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  margin: 0;
  padding: 0px 80px 30px 20px;
  background-color: #7f7f7f;
  color: #fff;
  min-height: 80px;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  margin: 0;
  padding: 20px 0;
  background-color: #1f1f1f;
  color: #fff;
  text-align: center;
`;

const FooterCard = styled.div`
  text-align: center;
`;

const FooterLink = styled.a`
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-style: normal;
`;

const FooterLinkIndent = styled.a`
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-style: normal;
  margin-right: 20px;
`;

const FooterText = styled.div`
  font-size: 14px;
`;

const FooterTextBottom = styled.div`
  font-size: 14px;
  color: #ffffffb3;
`;

const FooterHeader = styled.div`
  font-style: normal;
  font-size: 15px;
  font-weight: 700;
`;

const Logo = styled.img`
  width: 130px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 15px;
`;

export default function Footer() {
  const { strings } = useContext(LanguageContext);

  return (
    <>
      <FooterTop>
        <Grid $marginTop="2rem">
          <Row>
            <Col $size={1} $collapse="lg"></Col>
            <Col $size={1}>
              <FooterCard>
                <Logo src={logo} $align="center" />
                <FooterHeader>
                  {strings["Inovatyvus Jūsų verslo partneris"]}
                </FooterHeader>
                <FooterText>UADBB IVP Partners</FooterText>
                <FooterText>{strings["Įmonės kodas"]} 302489781</FooterText>
                <FooterText>
                  <FooterLink
                    href="https://www.ivp.lt"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.ivp.lt
                  </FooterLink>
                </FooterText>
                <FooterText>
                  <Email size={16} color="#fff" />
                  &nbsp;&nbsp;info@ivp.lt
                </FooterText>
              </FooterCard>
            </Col>
            <Col $size={1}>
              <FooterCard>
                <FooterHeader>{strings["Susisiekime"]}</FooterHeader>
                <FooterText>{strings["Pagrindinė būstinė"]}</FooterText>
                <FooterText>
                  {strings["Ulonų g. 2, Vilnius, LT-08245 Lietuva"]}
                </FooterText>
                <FooterText>
                  <Call size={14} color="#fff" />
                  &nbsp;+370 5 219 7601
                </FooterText>
                <p></p>
                <FooterText className="section-top-15">
                  {strings["Filialas Klaipėdoje"]}
                </FooterText>
                <FooterText>
                  {strings["Šilutės pl. 2-539, LT-91111 Klaipėda"]}
                </FooterText>
                <FooterText>
                  <Call size={14} color="#fff" />
                  &nbsp;+370 46 313049
                </FooterText>
              </FooterCard>
            </Col>
            <Col $size={1}>
              <FooterCard>
                <FooterHeader>{strings["Darbo valandos"]}</FooterHeader>
                <FooterText>{strings["Pirmadienis"]}</FooterText>
                <FooterText>{strings["Pietūs"]}</FooterText>
                <FooterText>{strings["Savitarna"]}</FooterText>
              </FooterCard>
            </Col>
            <Col $size={1} $collapse="lg"></Col>
          </Row>
          <Row style={{marginTop: "10px"}}>
            <Col $size={1}>
              <FooterCard>
                <FooterText>
                  <FooterLinkIndent
                    rel="nofollow noopener noreferrer"
                    href={strings["/informacija_klientui.pdf"]}
                    target="_blank"
                    className="link link-primary"
                  >
                    {strings["Informacija draudėjui"]}
                  </FooterLinkIndent>
                  <FooterLinkIndent
                    rel="nofollow noopener noreferrer"
                    href={strings["/duomenu_subjekto_atmintine.pdf"]}
                    target="_blank"
                    className="link link-primary"
                  >
                    {strings["Duomenų subjekto atmintinė"]}
                  </FooterLinkIndent>
                  <FooterLinkIndent
                    rel="nofollow noopener noreferrer"
                    href={strings["/tvarumo_politika.pdf"]}
                    target="_blank"
                    className="link link-primary"
                  >
                    {strings["Tvarumo politika"]}
                  </FooterLinkIndent>
                  <FooterLinkIndent
                    rel="nofollow noopener noreferrer"
                    href={strings["/Informacijos_saugumo_politika.pdf"]}
                    target="_blank"
                    className="link link-primary"
                  >
                    {strings["Informacijos saugumo politika"]}
                  </FooterLinkIndent>
                  <FooterLinkIndent
                    rel="nofollow noopener noreferrer"
                    href={strings["/slapuku_politika.pdf"]}
                    target="_blank"
                    className="link link-primary"
                  >
                    {strings["Slapukų politika"]}
                  </FooterLinkIndent>
                </FooterText>
              </FooterCard>
            </Col>
          </Row>
        </Grid>
      </FooterTop>
      <FooterBottom>
          <FooterTextBottom>
          © IVP Partners&nbsp;
            {new Date().getFullYear()}
          </FooterTextBottom>
      </FooterBottom>
    </>
  );
}
